<template>
  <div>
    <x-table
      ref="table"
      title="数据转发"
      :card="true"
      :options="options"
      custom-x-form
      @rowAdd="rowAdd"
      @rowView="toView"
      @rowEdit="toEdit"
      @rowDelete="rowDelete"
      @rowStart="rowStart"
      @rowStop="rowStop"
      @search="searchHandle">
    </x-table>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      width="85%"
      title="新增数据转发"
      :visible="visibleFlag"
      @hidden="sideHide"
    >
      <forward-form
        v-if="visibleFlag"
        :form-type="formType"
        :form-data="formData"
        @editSuccess="editSuccess"
      />
    </b-sidebar>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BSidebar,
} from 'bootstrap-vue'
import {
  query, remove, start, stop,
} from '@/api/data-center/forward'
import forwardForm from './forwardForm.vue'

export default {
  components: {
    XTable,
    BSidebar,
    forwardForm,
  },
  data() {
    return {
      formType: undefined,
      formData: {
        modelMeta: {
          sql: '',
          actions: [],
          whenErrorThen: [],
        },
      },
      // siderBar显示隐藏
      visibleFlag: false,
      content: ['aaaa', 'bbbb', 'cccc'],
      options: {
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          { action: 'view', name: '查看', icon: 'EyeIcon' },
          // actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}' },
          { action: 'edit', name: '编辑', icon: 'EditIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' },
          { action: 'start', name: '启动', icon: 'PlayIcon' },
          { action: 'stop', name: '停止', icon: 'PauseIcon' },
        ],
        actionFilter: (action, row) => {
          if (row.state.value === 'stopped') {
            return action === 'view' || action === 'edit' || action === 'delete' || action === 'start' || action === 'restart'
          }
          return action === 'view' || action === 'edit' || action === 'stop' || action === 'restart'
        },
        columns: [{
          label: 'ID',
          labelShow: true,
          prop: 'id',
          addShow: false,
          editShow: false,
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入D',
          },
        }, {
          label: '名称',
          labelShow: true,
          prop: 'name',
          editDisable: true,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入证书名称',
          },
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          addShow: false,
          rowSelectDot: true,
          selectVariant: prop => {
            if (prop.value === 'stopped') {
              return 'text-danger'
            }
            return 'text-success'
          },
          dictData: [{ text: '已停止', value: 'stopped' }, { text: '已启动', value: 'started' }],
          getDictLabel: option => option.label,
          props: { label: 'text', value: 'value' },
          searchShow: true,
        }, {
          label: '创建时间',
          labelShow: true,
          addShow: false,
          editShow: false,
          prop: 'createTime',
          searchShow: false,
          type: 'datetime',
        },
        ],
      },
    }
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    sideHide() {
      this.visibleFlag = false
      this.formData = {
        modelMeta: {
          actions: [],
          whenErrorThen: [],
        },
      }
    },
    rowAdd() {
      this.formType = this.$x.biz.FormType.ADD
      this.visibleFlag = true
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    // 编辑
    toEdit(row) {
      this.formType = this.$x.biz.FormType.EDIT
      this.formData = this._.cloneDeep(row)
      this.formData.modelMeta = JSON.parse(this.formData.modelMeta)
      if (!this.formData.modelMeta.sql) {
        this.formData.modelMeta.sql = ''
      }
      this.visibleFlag = true
    },
    toView(row) {
      this.formType = this.$x.biz.FormType.VIEW
      this.formData = this._.cloneDeep(row)
      this.formData.modelMeta = JSON.parse(this.formData.modelMeta)
      this.visibleFlag = true
    },
    rowStart(data, done) {
      start(data.id).then(() => {
        done()
      })
    },
    rowStop(data, done) {
      stop(data.id).then(() => {
        done()
      })
    },
    // 编辑成功
    editSuccess() {
      this.$xtoast.success('编辑成功！')
      this.$refs.slider.hide()
      this.formData = {
        modelMeta: {
          actions: [],
          whenErrorThen: [],
        },
      }
      this.$refs.table.__searchHandle()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
